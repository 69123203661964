import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import Button from '@mui/material/Button'
import { useRouter } from 'next/router'
import React from 'react'

interface iOfferButtonProps {
  data: ResultCard
  origin?: string
}

const OfferButton: React.FC<iOfferButtonProps> = ({ data, origin }) => {
  const router = useRouter()

  return (
    <>
      <Button
        data-cy="offerButton"
        onClick={() => {
          router.push({
            pathname: `/assinar/lead/${data.id}`,
            query: {
              origin: origin || data?.brand || ' Fale agora'
            }
          })
        }}
        startIcon={<LocalOfferIcon />}
        fullWidth
        variant="contained"
      >
        Solicitar proposta
      </Button>
    </>
  )
}

export default OfferButton
